
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./DonLeicht.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import EROPhoto1 from "../../../../res/Photos site/ERO/ero.png"
import EROPhoto2 from "../../../../res/Photos site/ERO/People-Magazine-comes-to-the-FUN-1983.-Photo-courtesy-of-Patti-Astor.-759x1024.jpg"
import EROPhoto3 from "../../../../res/Photos site/ERO/3.jpg"
import EROPhoto4 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Ero - Untitled - 1984.png"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/Leicht/portrait.jpg'
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "don-leicht",
  name: "DON LEICHT",
  description:
  "Don Leicht was born on October 12, 1946. He is a visual artist who has worked as a painter and sculptor in the Bronx, New York for over forty years. Leicht has had solo exhibitions in New York, Sweden and Germany and is one of the earliest figures in the downtown New York scene of the 1970s within the Street Art and Graffiti movements. Leicht began collaborating with Queens artist John Fekner with whom he shared a studio in 1976. In 1982 they began a series of works and installations using steel, cut metal, aluminum and automotive paint inspired by Nishikado, arcade games with the statement : Your space has been invaded. Don Leicht is a bit like the American Invader.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Don Leicht.",
  photos: [
    { src: EROPhoto1, name: "ERO" },
    { src: EROPhoto2, name: "ERO" },
    { src: EROPhoto3, name: "ERO" },
  ],
  citations: [
    "Leicht's piece consists of a sequence of creatures that exist only on a video screen - Pac Man, Donkey Kong and other Atari-like stablemates. Leicht cut the shapes of these hobby demons out of heavy aluminum plate and enameled them in their normal, unnatural colors. But each creature has also been abraded, with scratches in the enamel showing the metal beneath. One geometric thing - an abstract dog? an Imperial Walker? - has been scratched with a message like a graffito toilet stall or the \"Pray\" scratched on the metal of New York City phone booths » Glenn O’Brien",
  ]
};

const infos2 = {
  photos: [
    // { src: DanielArsham1, name: "Daniel Arsham", artist: "Daniel Arsham (1980)", title:"Ash Eroded Film Reel, 2013", desc1:"Cendres volcaniques, verre brisé, hydrostone", desc2:"Volcanic ash, shattered glass, hydrostone", desc3:"35 x 35cm", desc4:"14 7⁄8 x 14 7⁄8 in.", desc5:"Sculpture originale, pièce unique", desc6:"Original sculpture, unique work", desc7: "- Collection particulière.\n- Ron Mandos Gallery, Pays-Bas.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>", desc10:"<h4>- Un certificat de Ron Mandos Gallery sera remis à l'acquéreur.</h4>"  }
    { src: EROPhoto4, name: "ERO", artist:"ERO\n(Dominique Philbert dit) (1967 - 2011)", title:"Untilted, 1984", desc3: "120 x 254 cm", desc4:" 47 1/4 x 100 in.", desc7: "- Collection particulière." },
  ],
};

const DonLeicht = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div >
          <h1 className={TitleWrapper}>DON LEICHT<h2 style={{paddingLeft: "16px"}}>(American)</h2></h1>
          <h3 className={NameWrapper} >1946 - 2021</h3>
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
          <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }}
            >
              Contact-us
            </Button>
        </div>
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default DonLeicht;