// extracted by mini-css-extract-plugin
export var ArtistDescription = "DonLeicht-module--ArtistDescription--7Doaa";
export var ArtistInfos = "DonLeicht-module--ArtistInfos--n3WY9";
export var ButtonWrapper = "DonLeicht-module--ButtonWrapper --hLHDr";
export var CardWrapper = "DonLeicht-module--CardWrapper--qMdEb";
export var CarrouselWrapper2 = "DonLeicht-module--CarrouselWrapper2--JzbZ+";
export var Citations = "DonLeicht-module--Citations--YHdVe";
export var DescriptionWrapper = "DonLeicht-module--DescriptionWrapper--Jm1sq";
export var ImageWrapper = "DonLeicht-module--ImageWrapper--ZKBER";
export var LinkWrapper = "DonLeicht-module--LinkWrapper--+r5wp";
export var MobileProtrait = "DonLeicht-module--MobileProtrait--zxxs6";
export var More = "DonLeicht-module--More--D7XB2";
export var MoreButton = "DonLeicht-module--MoreButton--88Wvm";
export var NameWrapper = "DonLeicht-module--NameWrapper--Xdvpo";
export var PdpWrapper = "DonLeicht-module--PdpWrapper--rSjV4";
export var PhotosWrapper = "DonLeicht-module--PhotosWrapper--y1UJW";
export var ProfilWrapper = "DonLeicht-module--ProfilWrapper--cAecR";
export var TitleWrapper = "DonLeicht-module--TitleWrapper--HAFL6";
export var Wrapper = "DonLeicht-module--Wrapper--jRFlg";